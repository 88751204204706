import React from "react";
import "../../Styles/T3/T3MatchSummary.css";
import india_logo from "../../Assets/t3_india_img.png";
import pakistan_logo from "../../Assets/t3_pakistan_img.png";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import qs from "qs";
import team_default_logo from "../../Assets/t3_team_default_img.png";
import T3SummaryBottom from "./T3SummaryBottom";
import he from "he";
import { getDecryptedMatchId } from "../../DecryptingMatchId";

function T3MatchSummary() {
  let [searchParams] = useSearchParams();

  const [match_id, setMatch_Id] = useState("");


  // async function decryptData(encryptedData, password, iv) {
  //   const keyMaterial = await window.crypto.subtle.importKey(
  //     "raw",
  //     new TextEncoder().encode(password),
  //     { name: "PBKDF2" },
  //     false,
  //     ["deriveKey"]
  //   );

  //   const key = await window.crypto.subtle.deriveKey(
  //     {
  //       name: "PBKDF2",
  //       salt: iv,
  //       iterations: 100000,
  //       hash: "SHA-256",
  //     },
  //     keyMaterial,
  //     { name: "AES-CBC", length: 256 },
  //     false,
  //     ["decrypt"]
  //   );

  //   const decryptedData = await window.crypto.subtle.decrypt(
  //     { name: "AES-CBC", iv: iv },
  //     key,
  //     encryptedData
  //   );

  //   const decoder = new TextDecoder();
  //   return decoder.decode(decryptedData);
  // }

  // function stringToArrayBuffer(string) {
  //   const encoder = new TextEncoder();
  //   return encoder.encode(string).buffer;
  // }

  // async function decryptShortenedData(encryptedValue, password) {
  //   // Decode Base64 URL-safe format
  //   const binary = atob(encryptedValue.replace(/-/g, "+").replace(/_/g, "/"));

  //   // Extract IV and encrypted data
  //   const iv = new Uint8Array(
  //     binary
  //       .slice(0, 16)
  //       .split("")
  //       .map((c) => c.charCodeAt(0))
  //   );
  //   const encryptedData = new Uint8Array(
  //     binary
  //       .slice(16)
  //       .split("")
  //       .map((c) => c.charCodeAt(0))
  //   );

  //   // Decrypt using AES-CBC
  //   return await decryptData(encryptedData, password, iv);
  // }

  // async function decryptData(encryptedData, password, iv) {
  //   const keyMaterial = await crypto.subtle.importKey(
  //     "raw",
  //     stringToArrayBuffer(password),
  //     { name: "PBKDF2" },
  //     false,
  //     ["deriveKey"]
  //   );

  //   const key = await crypto.subtle.deriveKey(
  //     { name: "PBKDF2", salt: iv, iterations: 100000, hash: "SHA-256" },
  //     keyMaterial,
  //     { name: "AES-CBC", length: 256 },
  //     false,
  //     ["decrypt"]
  //   );

  //   const decryptedData = await crypto.subtle.decrypt(
  //     { name: "AES-CBC", iv: iv },
  //     key,
  //     encryptedData
  //   );

  //   return new TextDecoder().decode(decryptedData); // Convert ArrayBuffer to string
  // }
  // if (!window.crypto || !window.crypto.subtle) {
  //   console.error("Web Crypto API is not supported in this environment.");
  // }
  // useEffect(() => {
  //   const decryptMatchId = async () => {
  //     let paramValue = "";
  //     try {
  //       if (searchParams.has("match")) {
  //         paramValue = searchParams.get("match");
  //         console.log("Encrypted match param:", paramValue);

  //         // Ensure the value is valid before attempting decryption
  //         if (paramValue) {
  //           const decodedMatchId = await decryptShortenedData(
  //             paramValue,
  //             "myPassword"
  //           );
  //           console.log(decodedMatchId, "Decoded match_id");
  //           setMatch_Id(decodedMatchId.toString());
  //         } else {
  //           throw new Error("Invalid match parameter");
  //         }
  //       } else if (searchParams.has("match_id")) {
  //         paramValue = searchParams.get("match_id");
  //         const directMatchId = Number(paramValue);

  //         if (!isNaN(directMatchId)) {
  //           setMatch_Id(directMatchId.toString());
  //         } else {
  //           console.error("Invalid 'match_id' value.");
  //         }
  //       } else {
  //         console.log("Neither 'match' nor 'match_id' found in the URL.");
  //       }
  //     } catch (error) {
  //       console.error("Error during decryption:", error);
  //     }
  //   };

  //   decryptMatchId(); // Call the async function
  // }, [searchParams]);
  useEffect(() => {
    const fetchMatchId = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const decryptedMatchId = await getDecryptedMatchId(searchParams);
      setMatch_Id(decryptedMatchId);
    };

    fetchMatchId();
  }, []);

  const [summary, setSummary] = useState({
    teama_batting_players: [],
    teama_bowled_players: [],
    teama_name: "",
    teama_runs: "",
    teama_overs: "",
    teama_wickets: "",
    teamb_batting_players: [],
    teamb_name: "",
    teamb_runs: "",
    teamb_overs: "",
    teamb_wickets: "",
    teamb_bowled_players: [],
    result: "",
    teama_short_name: "",
    teamb_short_name: "",
    teama_logo: "",
    teamb_logo: "",
  });

  const reqData = {
    mod: "MatchScore",
    actionType: "get-match-summary-new",
    subAction: JSON.stringify({ MATCH_ID: match_id }),
  };
  useEffect(() => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL,
      data: qs.stringify(reqData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => {
      console.log(res, "res");
      let responseData = res.data.XSCData;
      setSummary({
        teama_batting_players: responseData.TEAM_A.BATTING,
        teama_bowled_players: responseData.TEAM_A.BOWLING,
        teama_name: he.decode(responseData.TEAM_A.TEAM_NAME),
        teama_runs: responseData.TEAM_A.RUNS,
        teama_wickets: responseData.TEAM_A.WICKETS,
        teama_overs: responseData.TEAM_A.OVERS,
        teamb_name: he.decode(responseData.TEAM_B.TEAM_NAME),
        teamb_batting_players: responseData.TEAM_B.BATTING,
        teamb_bowled_players: responseData.TEAM_B.BOWLING,
        teamb_runs: responseData.TEAM_B.RUNS,
        teamb_wickets: responseData.TEAM_B.WICKETS,
        teamb_overs: responseData.TEAM_B.OVER,
        result: he.decode(responseData.RESULT),
        teama_short_name: he.decode(responseData.TEAM_A.TEAM_NICK_NAME),
        teamb_short_name: he.decode(responseData.TEAM_B.TEAM_NICK_NAME),
        teama_logo: responseData.TEAM_A.TEAM_URL,
        teamb_logo: responseData.TEAM_B.TEAM_URL,
      });
    });
  }, [match_id]);
  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 10000);
  }, []);
  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  const playerNameCount = 13; // Set the maximum letter count
  const strikerElements = document.querySelectorAll(".t3_team_player_name");
  strikerElements.forEach((striker) => {
    if (striker.textContent.length > playerNameCount) {
      const truncatedText = striker.textContent.substring(0, playerNameCount);
      striker.textContent = truncatedText + "...";
    }
  });

  return (
    <>
       {showTicker ? ( 
        <div
          id="t3_match_bg"
          className="d-flex position-relative  justify-content-center"
          style={{ height: "100vh" }}
          data-aos="zoom-in"
          data-aos-delay="500"
        >
          {/* <div id="t3_match_title_bg">
            <img
              src={process.env.REACT_APP_API_URL + summary.teama_logo}
              onError={(e) => (e.target.src = team_default_logo)}
              alt="india_logo"
            />
            <div id="t3_match_teama_name">{he.decode(decodeNames(summary.teama_short_name))}</div>
            <div id="t3_match_text">MATCH SUMMARY</div>
            <div id="t3_match_teamb_name">{he.decode(decodeNames(summary.teamb_short_name))}</div>
            <div id="t3_match_teamb_img">
              <img
                src={process.env.REACT_APP_API_URL + summary.teamb_logo}
                onError={(e) => (e.target.src = team_default_logo)}
                alt="pak_logo"
              />
            </div>
          </div> */}
          <div id="t3_match_title_bg">
            <div id="t3_match_teama_name">
              <img
                src={process.env.REACT_APP_API_URL + summary.teama_logo}
                onError={(e) => (e.target.src = team_default_logo)}
                alt="india_logo"
              />
              <div>{he.decode(decodeNames(summary.teama_short_name))}</div>
            </div>
            <div id="t3_match_text">MATCH SUMMARY</div>
            <div id="t3_match_teamb_name">
              <div> {he.decode(decodeNames(summary.teamb_short_name))}</div>
              <img
                src={process.env.REACT_APP_API_URL + summary.teamb_logo}
                onError={(e) => (e.target.src = team_default_logo)}
                alt="pak_logo"
              />
            </div>
          </div>
          <div id="t3_match_blue_bg">
            <div id="t3_match_teama_bg">
              <div id="t3_match_bottom_teama_name" className="d-flex">
                {decodeNames(he.decode(summary.teama_short_name))}
                <span id="t3_match_teama_short_name" className="ps-2">
                  {" "}
                  ({decodeNames(he.decode(summary.teama_name))})
                </span>
              </div>
              <div id="t3_match_teama_runs">
                {summary.teama_runs}-{summary.teama_wickets}
                <div id="t3_match_teama_overs">OVERS {summary.teama_overs}</div>
              </div>
            </div>
            <div className="row w-100" id="t3_match_row">
              <div className="col-6 p-0  m-0">
                {summary.teama_batting_players === 0
                  ? ""
                  : summary.teama_batting_players.map((batting_players) => {
                      return (
                        <div className="d-flex">
                          <div id="t3_match_player_name_bg">
                            <div
                              id="t3_match_player_name"
                              className="t3_team_player_name"
                            >
                              {decodeNames(
                                he.decode(batting_players.FULL_NAME)
                              )}
                            </div>
                          </div>
                          <div id="t3_match_player_type_bg">
                            <div id="t3_match_player_runs" align="end">
                              {batting_players.RUNS}
                            </div>
                            <div id="t3_match_player_balls">
                              {batting_players.BALLS}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                {/* <div className="d-flex">
                <div id="t3_match_player_name_bg">
                  <div id="t3_match_player_name">rohit sharma</div>
                </div>
                <div id="t3_match_player_type_bg">
                  <div id="t3_match_player_type">BATTER</div>
                </div>
              </div>
              <div className="d-flex">
                <div id="t3_match_player_name_bg">
                  <div id="t3_match_player_name">virat kholi</div>
                </div>
                <div id="t3_match_player_type_bg">
                  <div id="t3_match_player_type">BATTER</div>
                </div>
              </div>
              <div className="d-flex">
                <div id="t3_match_player_name_bg">
                  <div id="t3_match_player_name">sanju samson</div>
                </div>
                <div id="t3_match_player_type_bg">
                  <div id="t3_match_player_type">BATTER</div>
                </div>
              </div> */}
              </div>
              <div className="col-6 p-0 m-0" id="t2_match_teamb_bg">
                {summary.teamb_bowled_players === 0
                  ? ""
                  : summary.teamb_bowled_players.map((bowled_players) => {
                      return (
                        <div className="d-flex">
                          <div id="t3_match_player_name_bg">
                            <div
                              id="t3_match_player_name"
                              className="t3_team_player_name"
                            >
                              {decodeNames(he.decode(bowled_players.FULL_NAME))}
                            </div>
                          </div>
                          <div id="t3_match_player_type_bg">
                            <div id="t3_match_bowler_data" align="end">
                              {bowled_players.WICKETS}-{bowled_players.RUNS}
                            </div>
                            <div id="t3_match_player_balls">
                              {Math.round(bowled_players.OVERS)}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div id="t3_match_teama_bg">
              <div id="t3_match_bottom_teama_name" className="d-flex">
                {he.decode(decodeNames(summary.teamb_short_name))}
                <span id="t3_match_teama_short_name" className="ps-2">
                  ({he.decode(decodeNames(summary.teamb_name))})
                </span>
              </div>
              <div id="t3_match_teama_runs">
                {summary.teamb_runs}-{summary.teamb_wickets}
                <div id="t3_match_teama_overs">OVERS {summary.teamb_overs}</div>
              </div>
            </div>
            <div className="row " id="t3_match_row">
              <div className="col-6 p-0 m-0">
                {summary.teamb_batting_players === 0
                  ? ""
                  : summary.teamb_batting_players.map((batting_players) => {
                      return (
                        <div className="d-flex">
                          <div id="t3_match_player_name_bg">
                            <div
                              id="t3_match_player_name"
                              className="t3_team_player_name"
                            >
                              {decodeNames(
                                he.decode(batting_players.FULL_NAME)
                              )}
                            </div>
                          </div>
                          <div id="t3_match_player_type_bg">
                            <div id="t3_match_player_runs" align="end">
                              {batting_players.RUNS}
                            </div>
                            <div id="t3_match_player_balls">
                              {batting_players.BALLS}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                {/* <div className="d-flex">
                <div id="t3_match_player_name_bg">
                  <div id="t3_match_player_name">rohit sharma</div>
                </div>
                <div id="t3_match_player_type_bg">
                  <div id="t3_match_player_type">BATTER</div>
                </div>
              </div>
              <div className="d-flex">
                <div id="t3_match_player_name_bg">
                  <div id="t3_match_player_name">virat kholi</div>
                </div>
                <div id="t3_match_player_type_bg">
                  <div id="t3_match_player_type">BATTER</div>
                </div>
              </div>
              <div className="d-flex">
                <div id="t3_match_player_name_bg">
                  <div id="t3_match_player_name">sanju samson</div>
                </div>
                <div id="t3_match_player_type_bg">
                  <div id="t3_match_player_type">BATTER</div>
                </div>
              </div> */}
              </div>
              <div className="col-6 p-0 m-0" id="t2_match_teamb_bg">
                {summary.teama_bowled_players === 0
                  ? ""
                  : summary.teama_bowled_players.map((bowled_players) => {
                      return (
                        <div className="d-flex">
                          <div id="t3_match_player_name_bg">
                            <div
                              id="t3_match_player_name"
                              className="t3_team_player_name"
                            >
                              {decodeNames(he.decode(bowled_players.FULL_NAME))}
                            </div>
                          </div>
                          <div id="t3_match_player_type_bg">
                            <div id="t3_match_bowler_data" align="end">
                              {bowled_players.WICKETS}-{bowled_players.RUNS}
                            </div>
                            <div id="t3_match_player_balls">
                              {Math.round(bowled_players.OVERS)}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                {/* <div className="d-flex">
                <div id="t3_match_player_name_bg">
                  <div id="t3_match_player_name">KL Rahul</div>
                </div>
                <div id="t3_match_player_type_bg">
                  <div id="t3_match_player_type">BATTER</div>
                </div>
              </div>
              <div className="d-flex">
                <div id="t3_match_player_name_bg">
                  <div id="t3_match_player_name">rohit sharma</div>
                </div>
                <div id="t3_match_player_type_bg">
                  <div id="t3_match_player_type">BATTER</div>
                </div>
              </div>
              <div className="d-flex">
                <div id="t3_match_player_name_bg">
                  <div id="t3_match_player_name">virat kholi</div>
                </div>
                <div id="t3_match_player_type_bg">
                  <div id="t3_match_player_type">BATTER</div>
                </div>
              </div> */}
              </div>
            </div>
            <div id="t3_match_result_bg">
              <div id="t3_match_result_text">
                {decodeNames(he.decode(summary.result))}
              </div>
            </div>
          </div>
        </div>
    ) : (
         <T3SummaryBottom />
       )} 
    </>
  );
}

export default T3MatchSummary;

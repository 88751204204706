export const getDecryptedMatchId = async (searchParams, password = "myPassword") => {
    const stringToArrayBuffer = (string) => new TextEncoder().encode(string).buffer;
  
    const decryptData = async (encryptedData, password, iv) => {
      const keyMaterial = await crypto.subtle.importKey(
        "raw",
        stringToArrayBuffer(password),
        { name: "PBKDF2" },
        false,
        ["deriveKey"]
      );
  
      const key = await crypto.subtle.deriveKey(
        { name: "PBKDF2", salt: iv, iterations: 100000, hash: "SHA-256" },
        keyMaterial,
        { name: "AES-CBC", length: 256 },
        false,
        ["decrypt"]
      );
  
      const decryptedData = await crypto.subtle.decrypt(
        { name: "AES-CBC", iv: iv },
        key,
        encryptedData
      );
  
      return new TextDecoder().decode(decryptedData);
    };
  
    const decryptShortenedData = async (encryptedValue, password) => {
      const binary = atob(encryptedValue.replace(/-/g, "+").replace(/_/g, "/"));
  
      const iv = new Uint8Array(
        binary
          .slice(0, 16)
          .split("")
          .map((c) => c.charCodeAt(0))
      );
      const encryptedData = new Uint8Array(
        binary
          .slice(16)
          .split("")
          .map((c) => c.charCodeAt(0))
      );
  
      return await decryptData(encryptedData, password, iv);
    };
  
    try {
      if (searchParams.has("match")) {
        const paramValue = searchParams.get("match");
        return await decryptShortenedData(paramValue, password);
      } else if (searchParams.has("match_id")) {
        const paramValue = searchParams.get("match_id");
        return Number(paramValue).toString();
      } else {
        throw new Error("Neither 'match' nor 'match_id' found in the URL.");
      }
    } catch (error) {
      console.error("Error during decryption:", error);
      return null;
    }
  };
  
import React, { useEffect, useState } from "react";
import "../../Styles/T3/T3Teams.css";
import india_logo from "../../Assets/t3_india_img.png";
import pakistan_logo from "../../Assets/t3_pakistan_img.png";
import { useSearchParams } from "react-router-dom";
import socketIOClient from "socket.io-client";
import team_default_logo from "../../Assets/t3_team_default_img.png";
import T3TossBottom from "./T3TossBottom";
import he from "he";
import { getDecryptedMatchId } from "../../DecryptingMatchId";

function T3Teams() {
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
  let [searchParams] = useSearchParams();
  const [match_id, setMatch_Id] = useState("");
  
  // async function decryptData(encryptedData, password, iv) {
  //   const keyMaterial = await window.crypto.subtle.importKey(
  //     "raw",
  //     new TextEncoder().encode(password),
  //     { name: "PBKDF2" },
  //     false,
  //     ["deriveKey"]
  //   );

  //   const key = await window.crypto.subtle.deriveKey(
  //     {
  //       name: "PBKDF2",
  //       salt: iv,
  //       iterations: 100000,
  //       hash: "SHA-256",
  //     },
  //     keyMaterial,
  //     { name: "AES-CBC", length: 256 },
  //     false,
  //     ["decrypt"]
  //   );

  //   const decryptedData = await window.crypto.subtle.decrypt(
  //     { name: "AES-CBC", iv: iv },
  //     key,
  //     encryptedData
  //   );

  //   const decoder = new TextDecoder();
  //   return decoder.decode(decryptedData);
  // }

  // function stringToArrayBuffer(string) {
  //   const encoder = new TextEncoder();
  //   return encoder.encode(string).buffer;
  // }

  // async function decryptShortenedData(encryptedValue, password) {
  //   // Decode Base64 URL-safe format
  //   const binary = atob(encryptedValue.replace(/-/g, "+").replace(/_/g, "/"));

  //   // Extract IV and encrypted data
  //   const iv = new Uint8Array(
  //     binary
  //       .slice(0, 16)
  //       .split("")
  //       .map((c) => c.charCodeAt(0))
  //   );
  //   const encryptedData = new Uint8Array(
  //     binary
  //       .slice(16)
  //       .split("")
  //       .map((c) => c.charCodeAt(0))
  //   );

  //   // Decrypt using AES-CBC
  //   return await decryptData(encryptedData, password, iv);
  // }

  // async function decryptData(encryptedData, password, iv) {
  //   const keyMaterial = await crypto.subtle.importKey(
  //     "raw",
  //     stringToArrayBuffer(password),
  //     { name: "PBKDF2" },
  //     false,
  //     ["deriveKey"]
  //   );

  //   const key = await crypto.subtle.deriveKey(
  //     { name: "PBKDF2", salt: iv, iterations: 100000, hash: "SHA-256" },
  //     keyMaterial,
  //     { name: "AES-CBC", length: 256 },
  //     false,
  //     ["decrypt"]
  //   );

  //   const decryptedData = await crypto.subtle.decrypt(
  //     { name: "AES-CBC", iv: iv },
  //     key,
  //     encryptedData
  //   );

  //   return new TextDecoder().decode(decryptedData); // Convert ArrayBuffer to string
  // }
  // if (!window.crypto || !window.crypto.subtle) {
  //   console.error("Web Crypto API is not supported in this environment.");
  // }
  // useEffect(() => {
  //   const decryptMatchId = async () => {
  //     let paramValue = "";
  //     try {
  //       if (searchParams.has("match")) {
  //         paramValue = searchParams.get("match");

  //         // Ensure the value is valid before attempting decryption
  //         if (paramValue) {
  //           const decodedMatchId = await decryptShortenedData(
  //             paramValue,
  //             "myPassword"
  //           );
  //           setMatch_Id(decodedMatchId.toString());
  //         } else {
  //           throw new Error("Invalid match parameter");
  //         }
  //       } else if (searchParams.has("match_id")) {
  //         paramValue = searchParams.get("match_id");
  //         const directMatchId = Number(paramValue);

  //         if (!isNaN(directMatchId)) {
  //           setMatch_Id(directMatchId.toString());
  //         } else {
  //           console.error("Invalid 'match_id' value.");
  //         }
  //       } else {
  //         console.log("Neither 'match' nor 'match_id' found in the URL.");
  //       }
  //     } catch (error) {
  //       console.error("Error during decryption:", error);
  //     }
  //   };

  //   decryptMatchId(); // Call the async function
  // }, [searchParams]);
  useEffect(() => {
    const fetchMatchId = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const decryptedMatchId = await getDecryptedMatchId(searchParams);
      setMatch_Id(decryptedMatchId);
    };

    fetchMatchId();
  }, []);
  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };

  const [teamName, setTeamName] = useState({
    teama_name: "",
    teama_short_name: "",
    teama_logo: "",
    teamb_name: "",
    teamb_logo: "",
    teamb_short_name: "",
    team_a_players: [],
    team_b_players: [],
    teama_squad_type: [],
    teamb_squad_type: [],
  });

  const [notOutPlayers, setNotOutPlayers] = useState("");
  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("connected", (data) => {
      // console.log("connected");
    });
    socket.on("live_score_new", (data) => {

      let mainData = data;
      setTeamName({
        teama_name: he.decode(mainData.TEAM_A.TEAM_NAME),
        teamb_name: he.decode(mainData.TEAM_B.TEAM_NAME),
        teama_short_name: he.decode(mainData.TEAM_A.TEAM_NICK_NAME),
        teamb_short_name: he.decode(mainData.TEAM_B.TEAM_NICK_NAME),
        team_a_players: mainData.TEAM_A.TEAM_SQUAD,
        team_b_players: mainData.TEAM_B.TEAM_SQUAD,
        teama_logo: mainData.TEAM_A.IMAGE_URL,
        teamb_logo: mainData.TEAM_B.IMAGE_URL,
      });
    });
    socket.emit("join", "match_id", match_id);
    socket.emit("get_score", match_id);

    return () => socket.disconnect();
  }, [match_id]);
  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 6000);
  }, []);
  const playerNameCount = 13; // Set the maximum letter count
  const strikerElements = document.querySelectorAll(".t3_teamb_player_name");
  strikerElements.forEach((striker) => {
    if (striker.textContent.length > playerNameCount) {
      const truncatedText = striker.textContent.substring(0, playerNameCount);
      striker.textContent = truncatedText + "...";
    }
  });

  return (
    <>
      {showTicker ? ( 
      <div
        id="t3_teams_bg"
        className="d-flex position-relative"
        style={{ height: "100vh" }}
      >
        <div id="t3_teams_title_bg">
          <div id="t3_teams_teama_name">
            <img
              src={process.env.REACT_APP_API_URL + teamName.teama_logo}
              onError={(e) => (e.target.src = team_default_logo)}
              alt="india_logo"
            />
            <div >
              {he.decode(decodeNames(teamName.teama_short_name))}
            </div>
          </div>

          <div id="t3_teams_text">TEAMS</div>
          <div id="t3_teams_teamb_name">
            <div >
              {he.decode(decodeNames(teamName.teamb_short_name))}
            </div>
            <div id="t3_teams_teamb_img">
            <img
              src={process.env.REACT_APP_API_URL + teamName.teamb_logo}
              onError={(e) => (e.target.src = team_default_logo)}
              alt="pak_logo"
            />
          </div>
          </div>

         
        </div>
        <div id="t3_teams_blue_bg">
          <div className="row w-100" id="t3_teams_row">
            <div className="col-6">
              {teamName.team_a_players.slice(0, 11).map((players) => {
                return (
                  <div id="t3_player_type_bg">
                    <div id="t3_teams_player_name_bg">
                      <div
                        id="t3_teams_player_name"
                        className="t3_teamb_player_name"
                      >
                        {decodeNames(he.decode(players.FULL_NAME))}
                      </div>
                    </div>
                    <div id="t3_teams_player_type_bg">
                      <div id="t3_teams_player_type">{players.SQUAD_TYPE}</div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-6 mx-0" id="t2_teams_teamb_bg">
              {teamName.team_b_players.slice(0, 11).map((players) => {
                return (
                  <div id="t3_player_type_bg">
                    <div id="t3_teams_player_name_bg">
                      <div id="t3_teams_player_name">
                        <span
                          id="t3_teamb_players_name"
                          className="t3_teamb_player_name"
                        >
                          {decodeNames(he.decode(players.FULL_NAME))}
                        </span>
                      </div>
                    </div>
                    <div id="t3_teams_player_type_bg">
                      <div id="t3_teams_player_type">{players.SQUAD_TYPE}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
     ) : (
        <T3TossBottom />
      )}
    </>
  );
}

export default T3Teams;
